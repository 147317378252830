import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-view',
    template: `<router-outlet></router-outlet>`
})

export class ViewsComponent implements OnInit {

    constructor() {
//intentionally left for future use

     }

    ngOnInit() {
//intentionally left for future use
    }
}